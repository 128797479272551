import React from "react";
import { useHistory } from "react-router-dom";
import { IMG_INTRO } from "../../assets";
import Button from "../../Components/Button";
import { Routes } from "../../Routes";

export default function Index() {
  const history = useHistory();
  return (
    <section className="intro">
      <a href="https://saphnelo.azil-consent.co.il/" className="logo-link"></a>
      <div className="section1">
        <div className="text1">לופוס בליבי, בואו לשחק עימי</div>
      </div>
      <div className="section2">
        <div className="text2">כל משתתף זוכה ...</div>
        <Button
          onClick={() => {
            window.location = "https://saphnelo.azil-consent.co.il/" as any;
            //history.push(Routes.main.question);
          }}
        >
          התחילו
        </Button>
      </div>
      {/* <img src={IMG_INTRO} alt="" /> */}
    </section>
  );
}
