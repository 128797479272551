import React from "react";

import './styles/main.scss';

import AppRoot from "./AppRoot";


function App() {
  return <AppRoot />;
}

export default App;
