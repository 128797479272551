import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { sysConfig } from "../config";



const CancelToken = axios.CancelToken;
// const source = axios.CancelToken.source();
export interface CancelTokenDelegate{
  cancelDelegate?:  (message?:string) => void
}
export function createHttpCanceler(ctd : CancelTokenDelegate) {
 
  const cancelToken = new CancelToken((c) => {
    ctd.cancelDelegate = c;
  });

  return cancelToken;
}
export default function httpClient(headers?:any): AxiosInstance {

  const logRequests = true;
  const logResponses = true;

  let client = axios.create({
    baseURL: sysConfig.API_URL,
    headers: {
      Accept: 'application/json',
      "Content-Type": 'application/json',
      ...headers
    }
  });
  // axiosRetry(client, { retries: 3 });
  function getUrl(config: AxiosRequestConfig) {
    if (config.baseURL) {
      return config.url?.replace(config.baseURL, '');
    }
    return config.url;
  }



  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

  function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
  }

  function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
      return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) body[key] = new Date(value);
      else if (typeof value === "object") handleDates(value);
    }
  }

  // Intercept all requests
  client.interceptors.request.use(
    async config => {
  

      if (logRequests) {

        console.log(`%c ${config?.method?.toUpperCase()} - ${getUrl(config)}:`, 'color: #0086b3; font-weight: bold', config);
      }

 

      return config;
    }, error => Promise.reject(error));
  // Intercept all responses
  client.interceptors.response.use(
    async response => {

      if (response) {
        
        if (logResponses) {

          console.log(`%c ${response.status} - ${getUrl(response.config)}:`, 'color: #008000; font-weight: bold', response);
        }
        handleDates(response.data);
      }
      return response;
    },
    error => {

      // const config = error.config
      // // you could defined status you want to retry, such as 503
      // // if (counter < max_time && error.response.status === retry_status_code) {
      // if (counter <= max_time) {
      //   counter++
      //   return new Promise((resolve) => {
      //     setTimeout(() => {

      //       resolve(axios(config));
      //     }, Math.random()*500);
      //   })
      // }

      if (error.response) {
        

        console.log(`%c ${error.response.status} - ${getUrl(error.response.config)}:`, 'color: #a71d5d; font-weight: bold', error.response);
        return Promise.reject({ error: error, data: error.response.data });
      }
      else {
        console.log(`%c ${error}:`, 'color: #a71d5d; font-weight: bold', error);
        return Promise.reject({ error: error, data: null });
      }


    });

  return client;
}