
import httpClient from "../Middleware/HttpClient";

import EventHandler from "../Middleware/EventHandler";



export abstract class BaseService {
    http = httpClient();

    onError = new EventHandler<BaseService, ExecuteResponse<any>>();
    onRequestStart = new EventHandler<BaseService, any>();
    onRequestEnd = new EventHandler<BaseService, any>();
   
    async safeExecuteAsync<TReturn>(callback: () => Promise<TReturn>): Promise<ExecuteResponse<TReturn>> {
        try {
            if (this.onRequestStart) {
                this.onRequestStart.trigger(this, null);
            }
            const data = await callback();
            if(data)
            {
                const _data = data as any;
               
            }
            return { data, isSuccess: true };
        } catch (e: any) {
            const { error, data } = e;

            // Special case when request is canceled - we dont notify and the message is not important
            if (error && error.message === 'cancel-planned') {
                return Promise.resolve({ isSuccess: false, error: 'Canceled request', exception: error, data: null as any })
            }

            let errorStr = 'Server error';

            // Try to extract some message
            if (error) {
                if (error.errors && error.errors.lenght > 0) {
                    errorStr = error.errors[0];
                }
                else {
                    errorStr = error.title
                        || error.message
                        || (error.StatusCode === 500 ? "500 (Internal Server Error)" : null)
                        || (error.Description)
                        || 'Server error';

                }
            }

            if (this.onError) {
                this.onError.trigger(this, { isSuccess: false, error: errorStr, exception: error, data: null });
            }

           
            
            return Promise.resolve({ isSuccess: false, error: errorStr, exception: error, data: null as any })
        }
        finally {
            if (this.onRequestEnd) {
                this.onRequestEnd.trigger(this, null);
            }
        }

    }
}

export interface ExecuteResponse<T> {
    data: T;
    error?: string;
    exception?: any;
    isSuccess: boolean;
}