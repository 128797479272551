import { AnswerModel } from "../Models/AnswerModel";
import { QuestionModel } from "../Models/QuestionModel";
import { BaseService } from "./BaseService";

export class QuestService extends BaseService {
  endpoints = {
    getQuestions: "questions/getQuestions",
    submitAnswers: "questions/submitAnswers",
  };

  async getQuestions() {
    
    return this.safeExecuteAsync(async () => {
      return (await this.http.get<Array<QuestionModel>>(
        this.endpoints.getQuestions
      )).data;
    });
  }

  async submitAnswers(answers: Array<AnswerModel>) {
    return this.safeExecuteAsync(async () => {
      return await this.http.post(this.endpoints.submitAnswers, answers);
    });
  }
}
