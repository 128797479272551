import React from "react";

interface ButtonProps {
  disabled?: boolean;
  onClick: () => void;
  children?: any;
}
export default function Button(props: ButtonProps) {
  return (
    <button onClick={props.onClick} disabled={props.disabled} className="app-button" type="button">
      {props.children}
    </button>
  );
}
