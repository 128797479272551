import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Routes } from "./Routes";
import Finish from "./Screens/Finish";
import Intro from "./Screens/Intro";
import Question from "./Screens/Question";

const Router = () => {


  return (
    <BrowserRouter>
      <div className="app">
          <Switch>
            <Route exact path={Routes.main.intro}>
              <Intro />
            </Route>
            <Route exact path={Routes.main.question}>
              <Question />
            </Route>

            <Route exact path={Routes.main.finish}>
              <Finish />
            </Route>

            <Redirect to="/" />
          </Switch>
        </div>
    </BrowserRouter>
  );
};

export default Router;
