import React from "react";
import Router from "./Router";


export default function AppRoot() {
  return (
    <Router/>
     
  );
}
