import React from "react";
import {
  IMG_ANS_1,
  IMG_ANS_1_ACT,
  IMG_ANS_2,
  IMG_ANS_2_ACT,
  IMG_ANS_3,
  IMG_ANS_3_ACT,
  IMG_ANS_4,
  IMG_ANS_4_ACT,
} from "../../../assets";
interface AnswerProps {
  num: number;
  text: string;
  isSelected: boolean;
  disabled: boolean;
  onPress: (n: number) => void;
}

export default function Answer(props: AnswerProps) {
  const getNumberImage = () => {
    switch (props.num) {
      case 1:
        return !props.isSelected ? (
          <img src={IMG_ANS_1} alt="" />
        ) : (
          <img src={IMG_ANS_1_ACT} alt="" />
        );
      case 2:
        return !props.isSelected ? (
          <img src={IMG_ANS_2} alt="" />
        ) : (
          <img src={IMG_ANS_2_ACT} alt="" />
        );
      case 3:
        return !props.isSelected ? (
          <img src={IMG_ANS_3} alt="" />
        ) : (
          <img src={IMG_ANS_3_ACT} alt="" />
        );
      case 4:
        return !props.isSelected ? (
          <img src={IMG_ANS_4} alt="" />
        ) : (
          <img src={IMG_ANS_4_ACT} alt="" />
        );

      default:
        break;
    }
  };
  return (
    <div
      className="answer-line"
      onClick={() => {
        if (!props.disabled) {
          props.onPress(props.num);
        }
      }}
    >
      {getNumberImage()}
      <div className="answer-text">{props.text}</div>
    </div>
  );
}
