import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { IC_ARROW_LEFT } from "../../assets";
import Button from "../../Components/Button";
import { AnswerModel } from "../../Models/AnswerModel";
import { QuestionModel } from "../../Models/QuestionModel";
import { Routes } from "../../Routes";
import { QuestService } from "../../Services/QuestService";
import Answer from "./Components/Answer";

const questService = new QuestService();

export default function Index() {
  const [questions, setQuestions] = useState<Array<QuestionModel>>([]);
  const [answers, setAnswers] = useState<Array<AnswerModel>>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showStats, setShowStats] = useState(false);
  const history = useHistory();
  useEffectOnce(() => {
    questService.getQuestions().then((x) => {
      const quests = x.data ?? [];

      setQuestions(quests);

      setAnswers(quests.map((z) => ({ answerId: 0, questionId: z.id })));
      setActiveIndex(0);
    });
  });
  const isSelected = (id: number) => {
    return (
      answers.find((x) => x.questionId === questions[activeIndex].id)
        ?.answerId === id
    );
  };

  const canContinue = () => {
    return (
      (answers.find((x) => x.questionId === questions[activeIndex].id)
        ?.answerId ?? 0) > 0
    );
  };

  const markSelected = (id: number) => {
    const _answers = [...answers];
    const ans = _answers.find(
      (x) => x.questionId === questions[activeIndex].id
    );
    if (ans) {
      ans.answerId = id;
    }
    setAnswers(_answers);
  };

  const next = async () => {
    if (activeIndex < questions.length - 1) {
      setShowStats(false);
      setActiveIndex(activeIndex + 1);
    } else {
      await questService.submitAnswers(answers);
      history.push(Routes.main.finish);
    }
  };

  if (questions.length === 0) {
    return null;
  }
  return (
    <div className="question-main">
      <a href="https://saphnelo.azil-consent.co.il/" className="logo-link"></a>
      <div className="left-corner-wrap">
        <div className="question-container">
          <div className="question">
            <div className="q-part-top"></div>
            <div className="q-part-mid">
              <div
                className="q-text"
                dangerouslySetInnerHTML={{
                  __html: questions[activeIndex].question1,
                }}
              ></div>
            </div>
            <div className="q-part-bottom"></div>
          </div>
        </div>

        <div className="answers-container">
          <div className="answers-list">
            <Answer
              disabled={showStats}
              num={1}
              onPress={(n) => {
                markSelected(n);
              }}
              isSelected={isSelected(1)}
              text={questions[activeIndex].answer1}
            />
            <Answer
              disabled={showStats}
              num={2}
              onPress={(n) => {
                markSelected(n);
              }}
              isSelected={isSelected(2)}
              text={questions[activeIndex].answer2}
            />
            <Answer
              disabled={showStats}
              num={3}
              onPress={(n) => {
                markSelected(n);
              }}
              isSelected={isSelected(3)}
              text={questions[activeIndex].answer3}
            />
            <Answer
              disabled={showStats}
              num={4}
              onPress={(n) => {
                markSelected(n);
              }}
              isSelected={isSelected(4)}
              text={questions[activeIndex].answer4}
            />
          </div>
          {!showStats && <div className="no-stats-container"></div>}
          {showStats && (
            <div className="stats-container">
              <div className="stats-title">איך ענו אחרים</div>
              <div className="stats-list">
                <div className="stat">
                  {questions[activeIndex].answer1Stats}%
                </div>
                <div className="stat">
                  {questions[activeIndex].answer2Stats}%
                </div>
                <div className="stat">
                  {questions[activeIndex].answer3Stats}%
                </div>
                <div className="stat">
                  {questions[activeIndex].answer4Stats}%
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="buttons-container">
          {!showStats && (
            <Button
              disabled={!canContinue()}
              onClick={async () => {
                setShowStats(true);
              }}
            >
              בחרו
            </Button>
          )}
          {showStats && (
            <Button
              disabled={!canContinue()}
              onClick={async () => {
                await next();
              }}
            >
              המשיכו &nbsp; &nbsp;
              <img style={{ height: "3rem" }} src={IC_ARROW_LEFT} />
            </Button>
          )}
          <a href="/">להתחלה</a>
        </div>
      </div>
    </div>
  );
}
