import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IC_THUMB_UP, IMG_LOGO } from "../../assets";
import Button from "../../Components/Button";
import { Routes } from "../../Routes";

export default function Index() {
  const history = useHistory();
  // useEffect(() => {
  //   setTimeout(() => {
  //     history.push(Routes.main.intro);
  //   }, 6000);
  // }, []);

  return (
    <section className="finish">
      <div className="section1">
        <a
          href="https://saphnelo.azil-consent.co.il/"
          className="logo-link"
        ></a>
        <div className="text1">תודה על ההשתתפות</div>
      </div>
      <div className="section2">
        <div className="text-center">
          <Button
            onClick={() => {
              window.location = "/" as any;
            }}
          >
            חזרה למשחק
          </Button>
          {/* <a href="/">
            <img src={IC_THUMB_UP} alt="" />
          </a> */}
        </div>
        <div className="notes">
          <img src={IMG_LOGO} alt="" />
          <p>
            AstraZeneca (Israel) Ltd.
            <br />
            Proprietary and Conﬁdential ©2022 AstraZeneca. All reserved. IL-3470
            11.2022
          </p>
          <p>
            <b>SLE</b>-Systemic Lupus Erythematosus <b>SRI</b>- SLE Responder
            Index <b>BICLA</b>- BILAG-Based Composite Lupus Assessment{" "}
            <b>CLASI</b>- Cutaneous Lupus Erythematosus Disease Area and
            Severity Index <b>PSI</b>- Psoriasis Area and Severity Index
          </p>
          <p>
            <b>Indication</b>: SAPHNELO is indicated for the treatment of adult
            patients with moderate to severe systemic lupus erythematosus (SLE),
            who are receiving standard therapy.
            <br />
            <b>Limitations of Use</b>: The efficacy of SAPHNELO® has not been
            evaluated in patients with severe active lupus nephritis or severe
            active central nervous system lupus. Use of SAPHNELO® is not
            recommended in these situations.
            <br />
            <b>Contraindications</b>: SAPHNELO® is contraindicated in patients
            with a history of anaphylaxis with anifrolumab Hypersensitivity to
            the active substance or to any of the excipients.
            <br />
            Please send any adverse events with information to the Safety mail
            box- safety.Israel@astrazeneca.com
          </p>
          <p>
            <b>Ref</b>:
            <br />
            1. Saphnelo® PI, as approved by MOH
            <br />
            2. Morand EF, et al. N Engl J Med. 2020;382:211-21
          </p>
        </div>
      </div>
      {/* <img src={IMG_INTRO} alt="" /> */}
    </section>
  );
}
